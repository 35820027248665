<template>
  <div class="actions-container w-100 text-center">
    <div
      v-tooltip="{
        content: !isSessionStarted
          ? $t('EVENTS.TEXT.BUTTON_WILL_BE_ENABLED_WHEN_THE_SESSION_STARTS')
          : '',
        delay: 100,
        placement: 'top',
        container: 'body',
      }"
    >
      <HMButton
        class="event-action mb-3"
        color="light-green"
        size="md"
        @click="$emit('joinSession')"
        v-if="canShowJoinSessionButton"
        :disabled="!isSessionStarted"
      >
        <span>{{ $t('EVENTS.BUTTON.JOIN_THE_LIVE_STREAM') }}</span>
      </HMButton>
    </div>
    <div
      v-tooltip="{
        content: getRegisterTooltipText,
        delay: 100,
        placement: 'top',
        container: 'body',
        trigger: 'click hover',
      }"
    >
      <div v-if="canShowFreeEventRegisterForm">
        <Form
          class="d-flex flex-column"
          @submit="$emit('registerForFreeEvent', freeEventForm)"
          tag="form"
          ref="form"
          v-slot="{ meta }"
        >
          <div
            class="d-flex flex-column flex-md-row"
            v-if="event.is_register_allowed"
          >
            <HMTextInput
              v-if="event.require_first_name"
              class="mb-0 mb-2 me-2"
              rules="required"
              name="first name"
              v-model="freeEventForm.first_name"
              :placeholder="$t('GENERAL.LABEL.FIRST_NAME')"
            />
            <HMTextInput
              v-if="event.require_last_name"
              class="mb-0 mb-2 me-2"
              rules="required"
              name="last name"
              v-model="freeEventForm.last_name"
              :placeholder="$t('GENERAL.LABEL.LAST_NAME')"
            />
            <HMTextInput
              class="mb-0 mb-2"
              type="email"
              rules="required|email"
              name="email"
              v-model.trim="freeEventForm.email"
              :placeholder="$t('EVENTS.TEXT.ENTER_YOUR_EMAIL_TO_REGISTER')"
            />
          </div>
          <HMButton
            type="submit"
            class="event-action mb-3"
            color="light-green"
            size="md"
            :disabled="
              !event.is_register_allowed ||
              registering ||
              isEventFull ||
              !meta.valid
            "
            :loading="registering"
          >
            <span>{{ $t('EVENTS.BUTTON.REGISTER_FOR_EVENT') }}</span>
          </HMButton>
        </Form>
      </div>
      <HMButton
        class="event-action mb-3"
        color="light-green"
        size="md"
        v-if="canShowRegisterButton"
        @click="$emit('registerForEvent')"
        :disabled="!event.is_register_allowed || registering || isEventFull"
        :loading="registering"
      >
        <span>{{ $t('EVENTS.BUTTON.REGISTER_FOR_EVENT') }}</span>
      </HMButton>
    </div>
    <HMButton
      class="event-action"
      color="brand"
      size="md"
      v-if="canShowRegisterForRecordingButton"
      @click="$emit('registerForRecording')"
      :disabled="registering"
      :loading="registering"
    >
      <span>{{ $t('EVENTS.BUTTON.WATCH_RECORDING') }}</span>
    </HMButton>
    <HMButton
      class="event-action"
      color="brand"
      size="md"
      v-if="showCheckoutButton"
      @click="$emit('goToShop')"
    >
      <span>
        {{
          recordingAvailable
            ? $t('EVENTS.BUTTON.GET_ACCESS_TO_RECORDING')
            : $t('EVENTS.BUTTON.GET_ACCESS_TO_EVENT')
        }}
      </span>
    </HMButton>
    <div v-if="isEventFull || !isRegistrationAllowed" class="mt-2 text-center">
      <h2 class="c-medium-gray">
        {{ getRegisterTooltipText }}
      </h2>
    </div>
    <HMButton
      class="event-action"
      color="mustard"
      size="md"
      v-if="canShowWaitlistRegisterButton"
      @click="$emit('registerForEventWaitlist')"
      :disabled="registering"
      :loading="registering"
    >
      <span>{{ $t('EVENTS.BUTTON.JOIN_THE_WAITLIST') }}</span>
    </HMButton>
    <template v-if="!unregistering">
      <HMActivatedTag
        v-if="canShowRegisteredSymbol"
        class="mb-2"
        :label="$t('EVENTS.LABEL.REGISTERED')"
        type="light"
      />
      <HMActivatedTag
        v-else-if="canShowWaitlistRegisteredSymbol"
        class="mb-2"
        :label="$t('EVENTS.LABEL.WAITLIST_REGISTERED')"
        type="light"
      />
      <div v-if="canShowStartSoonText" class="mb-2">
        <span v-if="!recordingAvailable">
          {{ $t('EVENTS.TEXT.EVENT_START_SOON_COME_BACK_LATER') }}
        </span>
        <span v-else>
          {{ $t('EVENTS.TEXT.EVENT_RECORDING_START_SOON_COME_BACK_LATER') }}
        </span>        
      </div>
      <div v-if="canShowUnregisterButton" class="mb-2">
        <span>
          {{ getUnregisterText }}
        </span>
        <div class="mt-3">
          <a
            href="#"
            class="unregister-button"
            :class="{ disabled: unregistering }"
            @click.prevent="$emit('unregisterFromEvent')"
            v-if="canShowUnregisterButton"
          >
            {{ $t('EVENTS.BUTTON.CANCEL_REGISTRATION') }}
          </a>
        </div>
      </div>
      <div v-else-if="canShowWaitlistUnregisterButton" class="mb-2">
        <span>
          {{ getWaitlistUnregisterText }}
        </span>
        <div class="mt-3">
          <a
            href="#"
            class="unregister-button"
            :class="{ disabled: unregistering }"
            @click.prevent="$emit('unregisterFromEventWaitlist')"
            v-if="canShowWaitlistUnregisterButton"
          >
            {{ $t('EVENTS.BUTTON.CANCEL_WAITLIST_REGISTRATION') }}
          </a>
        </div>
      </div>
    </template>
    <div v-else>
      <HMSpinner />
    </div>

    <div v-if="canShowLogin" class="mt-4 text-center">
      <span class="c-medium-gray">
        <span v-if="showCheckoutButton" class="me-2">{{ $t('EVENTS.LABEL.OR') }}</span>
        <span class="login-link pointer" @click="$emit('login')">
          {{ $t('EVENTS.LABEL.LOG_IN_SMALL') }}
        </span>
        {{ $t('EVENTS.LABEL.TO_CONTINUE') }}
      </span>
    </div>
  </div>
</template>

<script>
import { longDateTime } from '@/utils/events';
import { Form } from 'vee-validate';
import { EVENT_TYPE_LOCAL } from '@/utils/events';

export default {
  name: 'StudioEventActionComponent',
  components: { Form },
  props: {
    event: {
      type: Object,
    },
    isLoggedIn: {
      type: Boolean,
    },
    registering: {
      type: Boolean,
    },
    unregistering: {
      type: Boolean,
    },
  },
  computed: {
    showCheckoutButton() {
      return (
        this.needsAccess &&
        this.productsAreAvailableForPurchase &&
        (this.recordingAvailable ||
          (!this.isEventFull && this.isRegistrationAllowed))
      );
    },
    isCancelled() {
      return this.event.status === 'cancelled';
    },
    isExpired() {
      return this.event.status === 'expired';
    },
    productsAreAvailableForPurchase() {
      return this.event.all_products?.length > 0;
    },
    needsAccess() {
      if (this.isFreeEvent) return false;
      return this.event.accessNotAllowed || !this.isLoggedIn;
    },
    recordingAvailable() {
      return this.event.recordingAvailable;
    },
    isFreeEvent() {
      return this.event.is_free_event;
    },
    isBeforeEvent() {
      return this.event.isBeforeEvent;
    },
    hasClasscardAccess() {
      return this.event.status === 'classcard-access';
    },
    hasAccess() {
      return this.event.has_access;
    },
    isRegistrationAllowed() {
      return this.event.is_register_allowed;
    },
    isWaitingToStart() {
      return this.event.isWaiting;
    },
    isSessionStarted() {
      return this.event.isStarted;
    },
    recordingAvailable() {
      return this.event.recordings.length > 0;
    },
    canShowRegisterForRecordingButton() {
      // button is displayed if students need to get access to view the recording
      // this means student is not yet registered for the event and doesn't have a valid product access to the event
      if (!this.isLoggedIn) return false;
      if (this.needsAccess) return false;
      if (this.event.is_cancelled) return false;
      if (this.event.is_registered) return false;
      // allow recording to be available immediately after event start time
      if (!this.event.isAfterEventStartTime) return false;

      return this.recordingAvailable;
    },
    canShowRegisterButton() {
      if (this.event.is_cancelled) return false;
      if (this.event.isExpired) return false;
      if (this.event.is_registered) return false;

      if (this.recordingAvailable) return false;
      if (this.isLoggedIn && this.isFreeEvent && !this.isEventFull) return true;
      if (this.needsAccess) return false;
      if (!this.isLoggedIn) return false;
      if (this.isEventFull) return false;

      return this.hasClasscardAccess || this.event.registration_required;
    },
    canShowWaitlistRegisterButton() {
      if (this.event.is_cancelled) return false;
      if (this.event.isExpired) return false;
      if (this.event.is_registered) return false;
      if (this.event.is_waitlist_registered) return false;

      if (!this.isLoggedIn) return false;
      if (this.needsAccess) return false;
      if (!this.isEventFull) return false;
      //if (this.isFreeEvent) return false;

      return this.event.registration_required;
    },
    canShowFreeEventRegisterForm() {
      if (!this.isFreeEvent) return false;
      if (this.isLoggedIn) return false;
      if (this.needsAccess) return false;
      if (this.event.is_cancelled) return false;
      if (this.event.isExpired) return false;
      if (this.event.is_registered) return false;

      return true;
    },
    isEventFull() {
      if (!this.event.registration_required) return false;
      if (this.event.registration_limit === 0) return false;
      if (this.event.registration_limit > this.event.number_of_registrations)
        return false;

      return true;
    },
    canShowUnregisterButton() {
      if (this.event.isExpired) return false;
      if (!this.event.is_unregister_allowed) return false;
      if (this.isSessionStarted) return false;
      if (this.isWaitingToStart) return false;
      if (this.isFreeEvent && !this.isLoggedIn) return false;

      return this.event.is_registered;
    },
    canShowWaitlistUnregisterButton() {
      if (this.event.isExpired) return false;
      if (this.isSessionStarted) return false;
      if (this.isWaitingToStart) return false;

      return this.event.is_waitlist_registered;
    },
    canShowJoinSessionButton() {
      /**
       * Hide join session button when:
       * 1. event requires registration, but user isn't registered yet
       * 2. event requires registration, user is registered, but still has time to unregister
       * 3. event doesn't require registration, user is registered, but still has time to unregister
       *
       */
      const isLocalEvent = this.event.event_type === 'studio_event';

      if (isLocalEvent) return false;
      if (this.recordingAvailable) return false;
      if (this.needsAccess) return false;
      if (this.canShowRegisterButton) return false;
      if (this.canShowFreeEventRegisterForm) return false;

      const isUnregisterAllowed = this.event.is_unregister_allowed;
      const hasCorrectStatus = [
        'session-started',
        'waiting-to-start',
        'is-before-event',
      ].includes(this.event.status);

      if (this.isCancelled) return false;

      if (this.isRegistrationRequired && !this.isRegistered) return false;
      if (this.isSessionStarted) return true;
      if (this.isRegistered && isUnregisterAllowed) return false;

      return hasCorrectStatus;
    },
    isRegistered() {
      return this.event.is_registered;
    },
    isWaitlistRegistered() {
      return this.event.is_waitlist_registered;
    },
    isRegistrationRequired() {
      return this.event.registration_required;
    },
    canShowRegisteredSymbol() {
      if (!this.isLoggedIn && !this.isFreeEvent) return false;
      if (!this.isRegistered) return false;
      if (this.canShowJoinSessionButton) return false;
      if (this.canShowRegisterButton) return false;

      return true;
    },
    canShowWaitlistRegisteredSymbol() {
      if (!this.isLoggedIn && !this.isFreeEvent) return false;
      if (!this.isWaitlistRegistered) return false;
      // if (this.canShowJoinSessionButton) return false;
      // if (this.canShowRegisterButton) return false;

      return true;
    },
    canShowStartSoonText() {
      if (this.needsAccess) return false;
      if (!this.isLoggedIn) return false;
      if (this.event.is_cancelled) return false;
      if (this.event.isExpired) return false;
      if (!this.isBeforeEvent) return false;
      if (this.isSessionStarted) return false;
      if (this.canShowRegisterButton) return false;
      if (this.isRegistrationRequired && !this.isRegistered) return false;
      if (this.isInPersonEvent) return false;

      return true;
    },
    isInPersonEvent() {
      return this.event.event_type === EVENT_TYPE_LOCAL;
    },
    canShowLogin() {
      if (this.isFreeEvent && this.isRegistered) return false;
      if (this.isCancelled || this.isExpired) return false;
      return !this.isLoggedIn;
    },
    isUnregisterAllowed() {
      return this.event.is_unregister_allowed && !this.isSessionStarted;
    },
    isWaitlistUnregisterAllowed() {
      return !this.isSessionStarted;
    },
    getRegisterTooltipText() {
      if (this.isRegistrationAllowed) return '';
      if (this.isRegistered) return '';
      if (this.isEventFull) return this.$t('EVENTS.TEXT.EVENT_IS_FULL');
      const datetime = longDateTime(this.event.registration_close);
      if (!this.is_register_allowed)
        return this.$t('EVENTS.TEXT.EVENT_REGISTRATION_CLOSED_AT', {
          datetime,
        });

      return '';
    },
    getUnregisterText() {
      if (this.event.isExpired) return '';
      if (!this.event.is_registered) return '';

      if (this.isUnregisterAllowed) {
        if (this.event.unregistration_closes_minutes_before_start === 0) {
          return this.$t(
            'EVENTS.TEXT.EVENT_UNREGISTRATION_POSSIBLE_UNTIL_BEFORE_EVENT_START'
          );
        }

        return this.$t(
          'EVENTS.TEXT.EVENT_UNREGISTRATION_POSSIBLE_UNTIL_TIME_BEFORE_EVENT_START',
          {
            duration: this.humanizeMinutes(
              this.event.unregistration_closes_minutes_before_start
            ),
          }
        );
      } else {
        return this.$t(
          'EVENTS.TEXT.EVENT_UNREGISTRATION_IS_NO_LONGER_POSSIBLE'
        );
      }
    },
    getWaitlistUnregisterText() {
      if (this.event.isExpired) return '';
      if (!this.event.is_waitlist_registered) return '';

      if (this.isWaitlistUnregisterAllowed) {
        return this.$t('EVENTS.TEXT.EVENT_WAITLIST_UNREGISTRATION');
      } else {
        return this.$t(
          'EVENTS.TEXT.EVENT_WAITLIST_UNREGISTRATION_IS_NO_LONGER_POSSIBLE'
        );
      }
    },
  },
  data() {
    return {
      freeEventForm: {
        email: null,
        first_name: null,
        last_name: null,
      },
    };
  },
  methods: {
    humanizeMinutes(min) {
      const days = Math.floor(min / (24 * 60));
      const hours = Math.floor((min - days * (24 * 60)) / 60);
      const minutes = min - days * (24 * 60) - hours * 60;

      const valArray = [
        {
          value: days,
          label: this.$t('GENERAL.LABEL.DAY_VARIANT', days),
        },
        {
          value: hours,
          label: this.$t('GENERAL.LABEL.HOUR_VARIANT', hours),
        },
        {
          value: minutes,
          label: this.$t('GENERAL.LABEL.MINUTE_VARIANT', minutes),
        },
      ];

      return valArray
        .filter(e => e.value)
        .map(e => e.label)
        .join(' ');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';
.login-link {
  color: var(--brand-color, $coral);
  @include media-breakpoint-up(md) {
    color: $white;
  }
}

.event-action {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.event-action-icon {
  padding-right: 4px;
}

.registered-container {
  color: $light-green;
  background-color: rgba($light-green, 0.2);
  padding: 0.5rem 1rem;
  border-radius: $border-radius;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.unregister-button {
  padding: 0.5rem 1rem;

  color: $dark-gray;
  font-weight: 600;
  text-decoration: underline;
  @include media-breakpoint-up(md) {
    color: white;
  }
}
</style>
