<template>
  <countdown
    class="countdown"
    :time="time"
    :transform="transform"
    v-bind="$attrs"
    v-slot="props"
  >
    <div
      class="d-flex flex-row justify-content-center align-items-start flex-nowrap"
    >
      <div
        class="countdown-block d-flex flex-column justify-content-center align-items-center"
        v-if="props.days"
      >
        <span class="h1 c-white m-0 p-0">{{ pad2(props.days) }}</span>
        <span class="unit smallest">{{
          $t('GENERAL.LABEL.DAY_TEXT_VARIANT', props.days)
        }}</span>
      </div>
      <span v-if="props.days" class="h1 c-white mx-2"> : </span>
      <div
        class="countdown-block d-flex flex-column justify-content-center align-items-center"
        v-if="props.hours || props.days > 0"
      >
        <span class="h1 c-white m-0 p-0">{{ pad2(props.hours) }}</span>
        <span class="unit smallest">{{
          $t('GENERAL.LABEL.HOUR_TEXT_VARIANT', props.hours)
        }}</span>
      </div>
      <span v-if="props.hours || props.days > 0" class="h1 c-white mx-2">
        :
      </span>
      <div
        class="countdown-block d-flex flex-column justify-content-center align-items-center"
        v-if="props.minutes || props.days > 0 || props.hours > 0"
      >
        <span class="h1 c-white m-0 p-0">{{ pad2(props.minutes) }}</span>
        <span class="unit smallest">{{
          $t('GENERAL.LABEL.MINUTE_TEXT_VARIANT', props.minutes)
        }}</span>
      </div>
      <span
        v-if="props.minutes || props.days > 0 || props.hours > 0"
        class="h1 c-white mx-2"
      >
        :
      </span>
      <div
        class="countdown-block d-flex flex-column justify-content-center align-items-center"
      >
        <span class="h1 c-white m-0 p-0">{{ pad2(props.seconds) }}</span>
        <span class="unit smallest">{{
          $t('GENERAL.LABEL.SECOND_TEXT_VARIANT', props.seconds)
        }}</span>
      </div>
    </div>
  </countdown>
</template>

<script>
import countdown from '@chenfengyuan/vue-countdown';

function getTimeText(key, value) {
  const word = value < 2 ? key.replace(/s$/, '') : key;
  return `${value} ${word}`;
}

function getMinutesText(key, value) {
  const digits = value < 10 ? `0${value}` : value;
  return `0:${digits}m`;
}

export default {
  name: 'EventCountdown',
  components: {
    countdown,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  computed: {
    time() {
      if (!this.date) return null;
      return new Date(this.date) - Date.now();
    },
  },
  methods: {
    transform(props) {
      props.text = this.getCountdownText(props);
      return props;
    },
    pad2(number) {
      return String(number).padStart(2, '0');
    },
    getCountdownText({
      days,
      totalHours,
      hours,
      totalMinutes,
      minutes,
      totalSeconds,
      seconds,
    }) {
      const daysText = this.$t('GENERAL.LABEL.DAY_VARIANT', days);
      const hoursText = this.$t('GENERAL.LABEL.HOUR_VARIANT', hours);
      const minutesText = this.$t('GENERAL.LABEL.MINUTE_VARIANT', minutes);
      const secondsText = this.$t('GENERAL.LABEL.SECOND_VARIANT', seconds);
      const startsIn = this.$t('EVENTS.LABEL.STARTS_IN');
      const and = this.$t('GENERAL.LABEL.AND');
      if (totalHours >= 24 && hours)
        return `${startsIn} ${daysText} ${and} ${hoursText}`;
      else if (totalHours >= 24) return `${startsIn} ${daysText}`;
      else if (totalHours)
        return `${startsIn} ${hoursText} ${and} ${minutesText}`;
      else if (totalMinutes)
        return `${startsIn} ${minutesText} ${and} ${secondsText}`;
      else if (totalSeconds) return `${startsIn} ${secondsText}`;
      else return this.$t('EVENTS.TEXT.EVENT_WAITING_TO_START');
    },
  },
};
</script>

<style lang="scss" scoped>
.unit {
  line-height: 26px;
}
.countdown-block {
  flex-basis: 25%;
  min-width: 2rem;
}
</style>
