<template>
  <div class="hm-attachment d-flex align-items-center">
    <div class="icon-wrapper me-2">
      <i :class="mediaTypeIcon"></i>
    </div>
    <span class="file-name">
      {{ cleanedFileName(attachment.file_name) }}
    </span>
    <HMIconButton
      :icon="['far', 'fa-file-download']"
      @click="downloadAttachment(attachment)"
    />
    <HMIconButton
      v-if="hasRemoveListener"
      :icon="['far', 'fa-trash-alt']"
      @click="$emit('remove')"
    />
  </div>
</template>

<script lang="ts">
import { cleanedFileName } from '@/utils/utils';
import { getMediaTypeIconClasses } from '@/utils/media';

export default {
  props: {
    attachment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    mediaTypeIcon() {
      if (!this.attachment) return '';
      return getMediaTypeIconClasses(this.attachment?.file_content);
    },
    hasRemoveListener() {
      return this.$attrs?.remove;
    },
  },
  methods: {
    downloadAttachment(file) {
      return window.open(file.storage_url, '_blank');
    },
    cleanedFileName,
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.icon-wrapper {
  color: var(--brand-color, $coral);
  background-color: var(
    --brand-color-with-opacity-03,
    rgba($light-bubblegum, 0.3)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  min-width: 31px;
  min-height: 31px;
}

.file-name {
  flex: 1;
}
</style>
