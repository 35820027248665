<template>
  <div class="d-flex flex-column">
    <div class="d-flex">
      <div>
        <span class="c-charcoal">{{ day }}</span> - {{ date }}  
      </div>
      <span class="mx-2 c-charcoal"></span>
      <div>
        <span class="c-charcoal">{{ startTime }}</span>
        {{ $t('GENERAL.LABEL.YOUR_TIME') }}
      </div>
    </div>
    <div class="mt-2 mt-md-0 c-charcoal" v-if="location">
      {{ location }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudioEventDateTimeComponent',
  props: {
    day: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    startTime: {
      type: String,
      default: '',
    },
    endTime: {
      type: String,
      default: '',
    },
    location: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';
</style>
