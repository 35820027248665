<template>
  <div class="activated-container" :class="`activated-container--${type}`">
    <span class="icon-wrap me-1">
      <i  class="icon fa fa-check" ></i>
    </span>
    {{ label }}
  </div>
</template>

<script>
export default {
  name: 'HMActivatedTag',
  props: {
    label: {
      type: String,
    },
    type: {
      type: String,
      default: 'normal',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.activated-container {
  padding: 0.5rem 1rem;
}

.activated-container--normal {
  color: $light-green;
  background-color: rgba($light-green, 0.2);
  padding: 0.5rem 1rem;
  border-radius: $border-radius;

  @include media-breakpoint-down(md) {
    background-color: transparent;
  }
}

.activated-container--light {
  color: $charcoal;
  background-color: rgba(white, 0.8);
  border-radius: $border-radius;

  .icon-wrap {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: $light-green;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: white;
  }

  .icon {
    height: 11px;
  }
}
</style>
